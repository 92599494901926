const siloCarrot = [
  "/chatbot/case-skillfactory/",
  "/chatbot/case-pimaschool/",
  "/chatbot/case-horizons-group/",
  "/chatbot/chatbot-best-practices/",
  "/chatbot/chat-bot-saas-case/",
  "/chatbot/best-chatbots-for-your-website/",
  "/chatbot/future-of-chatbots/",
  "/chatbot/7-tips-on-building-chatbots/",
  "/chatbot/chatbot-templates-for-saas/",
  "/chatbot/bot-is-no-more-beta/",
  "/chatbot/leadbot-calendly-zoom/",
  "/chatbot/chatbot-types/",
  "/chatbot/chatbot-templates-playbook/",
  "/chatbot/lead-bot-carrot-quest/",
  "/pop-up/case-mnogosna/",
  "/pop-up/kejs-instrukciya-kak-pokazyvat-tovary-kotorye-posmotrel-polzovatel-za-tekushhuyu-sessiyu/",
  "/pop-up/kejs-ab-test-podpiski-na-rassylku/",
  "/pop-up/kejs-saas-servisa/",
  "/pop-up/case-iq-option/",
  "/pop-up/case-spk/",
  "/pop-up/case-nagorniy/",
  "/pop-up/case-dom-ru-konversii/",
  "/pop-up/case-avtomir/",
  "/pop-up/case-seasonal-business/",
  "/pop-up/10-gotovyh-scenariev/",
  "/pop-up/35-awesome-popups/",
  "/pop-up/scenarii-pop-apov/",
  "/pop-up/konstruktor-pop-apov/",
  "/pop-up/mobilnye-pop-apy/",
  "/pop-up/statistika-a-b-testov/",
  "/pop-up/kak-rabotayut-pop-apy-carrot-quest/",
  "/pop-up/triggernyj-chat-i-pop-up-speshat-na-pomoshh-esli-polzovatel-ne-ostavil-email/",
];

const growthHubDashly = [
  "/guides/growth-marketing/",
  "/blog/growth-hacking-part-one/",
  "/blog/product-led-growth-marketing/",
  "/blog/growth-marketing-team/",
  "/blog/growth-hypothesis/",
  "/blog/growth-hacking-books/",
  "/blog/growth-marketing-tips/",
  "/blog/growth-hacking-tools/",
  "/blog/growth-hacking-examples/",
  "/blog/what-is-growth-hacking/",
  "/blog/marketing-growth-strategy/",
  "/blog/social-media-growth-hacking-tools/",
  "/blog/growth-marketing-services/",
  "/blog/growth-marketing-metrics/",
  "/blog/growth-hacking-canvas/",
  "/blog/find-a-growth-hacker-for-your-startup/",
  "/blog/growth-hacking-facebook/",
  "/blog/growth-hacks/",
  "/blog/growth-marketing-playbook/",
  "/blog/growth-hacking-2/",
  "/blog/growth-team-interview/",
  "/blog/free-growth-hacking-tools/",
  "/blog/growth-team-interview-part-2/",
  "/blog/growth-marketing-interaction/",
  "/blog/growth-hacking-edtech/",
  "/blog/growth-marketing-test/",
  "/blog/creating-the-growth-team-from-product-to-processes/",
  "/blog/how-to-launch-products-and-maintain-growth/",
  "/blog/what-is-growth-marketing/",
  "/blog/b2b-growth-hacking-strategies/",
  "/blog/b2b-growth-marketing/",
  "/blog/saas-growth-hacks/",
];

exports.onRouteUpdate = ({ location }) => {
  switch (true) {
    case location.href.indexOf("/blog/") > -1 || location.href.indexOf("/guides/") > -1 || siloCarrot.indexOf(location.pathname) > -1 || location.href.indexOf("/cases/") > -1 || location.href.indexOf("/library/") > -1:
      carrotquest.track("Зашел на блог", {
        URL: location.href,
      });
      break;
    case location.href.indexOf("/chatbot/") > -1:
      carrotquest.track("Зашел на страницу чат-ботов", {
        URL: location.href,
      });
    default:
      carrotquest.track("Зашел на лендинг", {
        URL: location.href,
      });
      break;
  }
  // Состояние для поп‑апов
  carrotquest.identify([{ op: "update_or_create", key: "popup", value: "closed" }]);

  // Запись invited_by в карточку пользователя на Carrot quest
  if (location.href.indexOf("invited_by=") > -1) {
    let inv_str_url = location.href.split("invited_by=")[1].split("&")[0];

    //Записываем параметр с id Партнёра в куку
    document.cookie = "invited_by=" + inv_str_url + "; domain=carrotquest.io; path=/; max-age=5184000;";

    //Записываем параметр с id Партнёра в пользователя
    carrotquest.identify({ invited_by: inv_str_url });
  }

};

